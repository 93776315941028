import classes from './Plataformas.module.css';

const arrPlataformas = [
    { name: 'Spreaker', url: 'https://www.spreaker.com/show/yo-soy-tu-padre', order: 3, icon: 'Spreaker_Icon.png' },
    { name: 'Spotify', url: 'https://open.spotify.com/show/5NmgJ5Tz5Z4YPI686l5MMz', order: 2, icon: 'Spotify_Icon.png' },
    { name: 'Ivoox', url: 'https://www.ivoox.com/podcast-yo-soy-tu-padre_sq_f1482135_1.html', order: 4, icon: 'Ivoox_Icon.png' },
    { name: 'Apple Podcast', url: 'https://podcasts.apple.com/es/podcast/yo-soy-tu-padre/id1321283712', order: 5, icon: 'Apple_Podcast_Icon.png' },
    { name: 'Feed RSS', url: 'https://feedpress.me/yosoytupadre', order: 1, icon: 'RSS_Icon.png' },
    { name: 'Youtube', url: 'https://www.youtube.com/channel/UC7rUD0Bk3JWWZ0XU8Acf9gg', order: 6, icon: 'Youtube_Icon.png' }
]

const Plataformas = () => {
    return <div className="row text-center">
        <h3 className="mb-4">Disponible en las siguientes plataformas</h3>
        {arrPlataformas.sort(compare).map(platform => (
            <div className={['col-md-2 col-4', classes.platform].join(' ')}>
                <a href={platform.url} target="_blank">
                    <figure>
                        <img src={'images/' + platform.icon} alt="" />
                        <figcaption>{platform.name}</figcaption>
                    </figure>
                </a>
            </div>
        ))}
    </div>
};

function compare(a, b) {
    if (a.order < b.order) {
        return -1;
    }
    if (a.order > b.order) {
        return 1;
    }
    return 0;
}

export default Plataformas;