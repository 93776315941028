import logo from './logo.svg';
import './App.css';
import Plataformas from './components/Plataformas/Plataformas';
import UltimoEpisodio from './components/UltimoEpisodio/UltimoEpisodio';

function App() {
  return (
    <div className="container">
      <img src="images/banner.png" className="banner mt-4" />
      <hr />
      <h1 className="display-1 text-center mt-5">Yo soy tu padre Podcast</h1>
      <h2 className="text-center green-color subtitulo col-md-8 offset-md-2 col-12 offset-0 mt-4">Las aventuras y desventuras en formato audio de dos padres ya no tan primerizos.
        <br /><br />
        <strong className='white-color'>Disponible la segunda temporada: ¿Dos mejor que uno?</strong>
      </h2>
      <div className="row">
        <div className="col-md-6 offset-md-3 col-12 offset-0 py-5">
          <Plataformas />
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 offset-md-2 col-12 offset-0">
          {/* <UltimoEpisodio /> */}
        </div>
      </div>
    </div>
  );
}

export default App;
